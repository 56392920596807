<template lang="pug">
    Aside(:ativo="ativo", @close="ativo = false")#asideLinhaDoTempo
        Heading(:contents="title")
        .content
            ul
                li(v-for="item in timeline.years").item
                    h3 {{ item.year }}
                    component(:is="componentItem.component", :contents="componentItem.contents", v-for="componentItem, index in mappedComponents(item.content)", :key="`${componentItem.type}-${index}`")

</template>

<script>
import Aside from '@components/Aside/Aside'
import { textComponents, textDictionary } from '@/mixins/component'

export default {
    name: "component-aside-linha-do-tempo",
    components: {
        Aside,
        ...textComponents,
    },
    computed: {
        ativo: {
            get() {
                return this.$store.state.linhaDoTempo.ativo
            },
            set(value) {
                this.$store.commit('setLinhaDoTempo', { ativo: value, content: {} })
            }
        },
        timeline() {
            return this.$store.state.linhaDoTempo.content
        },
        title() {
            return {
                content: `${this.timeline.name}`,
                level: 2
            }
        },
    },
    methods: {
        mappedComponents(item) {
			let parsed = JSON.parse(item)
            return parsed.map(item => {
                return { component: this.$options.components[textDictionary[item.type]], contents: item, type: item.type }
            })
        }
    },
}
</script>

<style lang="stylus" scoped src="./AsideLinhaDoTempo.styl"></style>
