
<template lang="pug">
    Aside(:ativo="ativo", @close="ativo = false")#asideImage
        .content
            .img
                img(:src="image.image").image
            p(v-if="image.text !== ''" v-html="image.text")
</template>

<script>
import Aside from '@components/Aside/Aside'

export default {
    name: "component-aside-image",
    components: {
        Aside,
    },
    computed: {
        ativo: {
            get() {
                return this.$store.state.imageModal.ativo
            },
            set(value) {
                this.$store.commit('setImage', { ativo: value, content: {} })
            }
        },
        image() {
            // return this.$store.state.imageModal.content
            return {
                ...this.$store.state.imageModal.content, 
                image: `${process.env.VUE_APP_FILE_API_ROUTE}/medias/media/${this.$store.state.imageModal.content.image}`
            }
        },
    },
}
</script>

<style lang="stylus" scoped src="./AsideImage.styl"></style>