<template lang="pug">
	main(@keyup.esc="handleCloseSearch()")#app
		Header
		article#page
			router-view
			Footer
		AsideLinhaDoTempo
		AsideAcessoRapido
		AsideImage
		Cookies
</template>

<script>
import AsideAcessoRapido from "@components/AsideAcessoRapido/AsideAcessoRapido";
import AsideLinhaDoTempo from "@components/AsideLinhaDoTempo/AsideLinhaDoTempo";
import AsideImage from '@components/AsideImage/AsideImage'
import Footer from "@components/Footer/Footer";
import Header from "@components/Header/Header";
import Cookies from "@sections/Pagina/Cookies/Cookies";

export default {
	name: 'template-app',
	metaInfo() {
		return {
			titleTemplate: (title) => title ? `${title} - Coamo Agroindustrial Cooperativa` : 'Coamo Agroindustrial Cooperativa',
			meta: [
				{ name: 'description', content: '' },
			],
		}
	},
	components: {
		Header,
		Footer,
		AsideLinhaDoTempo,
		AsideAcessoRapido,
		AsideImage,
		Cookies,
	},
	methods: {
		handleCloseSearch() {
			this.$store.commit('search/setDisplay', false)
		}
	}
}
</script>

<style lang="stylus" scoped src="./App.styl"></style>
