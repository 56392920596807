<template lang="pug">
    aside#asideAcessoRapido(:class="{ ativo }")
        .box(@mouseover="openAtivo()", @mouseleave="closeAtivo()")
            .content
                ul.links
                    li(v-for="item in links")
                        Link(:contents="item") {{ item.title }}
                .precoDoDia
                    p Preço do Dia
                    .tabela
                        select(v-model="precoId")
                            option(:value="null" disabled) CAMPO MOURÃO - PR
                            option(v-for="unidade in unidadesPreco", :value="unidade.codigoUnidade") {{ unidade.nomeUnidade }} - {{ unidade.codigoUf }}
                        span(v-if="preco.precos").alta {{ preco.precos[0].nomeProduto }}: {{ valor(preco.precos[0].valorPreco) }} | {{preco.precos[0].codigoUnidadePadrao}}
                        span(v-if="preco.precos").baixa {{ preco.precos[1].nomeProduto }}: {{ valor(preco.precos[1].valorPreco) }} | {{preco.precos[1].codigoUnidadePadrao}}
                    a(href="/preco-do-dia")
                        FontAwesomeIcon(:icon="icons.faChevronRight").openPreco
                .previsao
                    .left
                        p.gray Previsão do Tempo
                        span(v-if="previsao.municipio").escolha {{ previsao.municipio.nome}}, {{previsao.municipio.uf}}    
                        select(v-model="tempoId")
                            option(:value="null" disabled) MUDAR LOCAL
                            option(v-for="unidade in unidadesTempo", :value="unidade.codigoUnidade") {{unidade.nomeUnidade}}
                    .right
                        .clima
                            SvgIcon(data="@svgs/clima.svg", original)
                            span(v-if="previsao.previsoes") {{ previsao.previsoes[0].temperaturaMinima}}° | {{ previsao.previsoes[0].temperaturaMaxima}}°
                        .precipitacao
                            SvgIcon(data="@svgs/precipitacao.svg", original)
                            span(v-if="previsao.previsoes") {{ previsao.previsoes[0].precipitacao}} mm
                    FontAwesomeIcon(:icon="icons.faChevronRight", @click="$store.commit('setModal','previsao')").openClima
            button(@click="toggleAtivo()")
                FontAwesomeIcon(:icon="icons.faCaretRight")
        div(v-if="$store.state.modal == 'previsao'").modal
            div.modalPrevisao
                button(@click="$store.commit('setModal',null)").closeButton
                    FontAwesomeIcon(:icon="icons.faXmark")
                .header
                    h4 Previsão do Tempo
                    .select
                        p Selecione o local
                        select(v-model="tempoId")
                            option(:value="null" disabled) CAMPO MOURÃO
                            option(v-for="unidade in unidadesTempo", :value="unidade.codigoUnidade") {{unidade.nomeUnidade}}
                ul(v-if="previsao.previsoes").listaPrevisao
                    li(v-for="clima in previsao.previsoes")
                        img(:src="selectImg(clima.codigoImagem)")
                        span.data {{ formatDate(clima.dataPrevisao) }}
                        span.day {{ formatDay(clima.dataPrevisao) }}
                        span.status {{ clima.descricaoPrevisao }}
                        span.minima Min: {{ clima.temperaturaMinima}}°
                        span.maxima Máx: {{ clima.temperaturaMaxima}}°
                        span.precip {{ clima.precipitacao}} mm
</template>

<script>
import { components as allComponents } from '@/mixins/component';
import { faCaretRight, faChevronRight, faXmark } from '@fortawesome/free-solid-svg-icons';

export default {
    name: "component-aside-acesso-rapido",
    components: {
        ...allComponents,
    },
    data() {
        return {
            icons: {
                faCaretRight,
                faChevronRight,
                faXmark
            },
            ativo: false,
            links: [
                {
                    title: `Portal do Cooperado`,
                    external: true,
                    link: `https://servicos.coamo.com.br/uniface/wrd/painelcoop`
                },
                {
                    title: `Coamo Alimentos`,
                    external: true,
                    link: `https://coamoalimentos.com.br`
                },
                {
                    title: `Notícias`,
                    external: false,
                    link: {
                        path: `/pt-br/comunicacao/noticias`
                    }
                },
                {
                    title: `Fale Conosco / SAC`,
                    external: false,
                    link: {
                        path: `/pt-br/fale-conosco`
                    }
                },
                {
                    title: `Cooperativismo`,
                    external: false,
                    link: {
                        path: `/pt-br/institucional/cooperativismo/como-funciona`
                    }
                },
                {
                    title: `Coamo em Números`,
                    external: false,
                    link: {
                        path: `/pt-br/institucional/quem-somos/coamo-em-numeros`
                    }
                },
                {
                    title: `Acessibilidade`,
                    external: true,
                    link: `#`
                },
            ],
            previsao: {},
            preco: {},
            unidadesTempo: [],
            unidadesPreco: [],
            tipoUnidade: {},
            tempoId: null,
            precoId: null,
        }
    },
    methods: {
        openAtivo() {
            this.ativo = true;
        },
        closeAtivo() {
            this.ativo = false;
        },
        toggleAtivo() {
            this.ativo = !this.ativo
        },
        getCampo() {
            this.$axios.get('coamo-api/campo-mourao')
            .then(response => {
                this.preco = response.data.preco
                this.previsao = response.data.clima
            })
            .catch((_) => { return })
        },
        valor(valor) {
            return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL'}).format(valor)
        },
        formatDate(date, full = false) {
            const objData = new Date(date)
            let formatted;
            if (full) {
                formatted = `${objData.getDate()}/${objData.getMonth()}/${objData.getFullYear()} ${objData.getHours()}:${objData.getMinutes()}`
            } else {
                formatted = `${objData.getDate()}/${objData.getMonth()+1}`
            }
            return formatted
        },
        formatDay(date) {
            const objData = new Date(date)
            const days = ['Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado', 'Domingo']
            let formatted = `${days[objData.getDay()]}`;
            return formatted
        },
        getUnidadeClima() {
            this.$axios.get('coamo-api/clima')
            .then(response => {
                this.unidadesTempo = response.data
            })
            .catch((_) => { return })
        },
        getUnidadePreco() {
            this.$axios.get('coamo-api/preco')
            .then(response => {
                this.unidadesPreco = response.data
            })
            .catch((_) => { return })
        },
        getClima() {
            this.$axios.get(`coamo-api/previsao/${this.tempoId}`)
            .then(response => {
                this.previsao = response.data
            })
        },
        getPreco() {
            this.$axios.get(`coamo-api/preco/${this.precoId}`)
            .then(response => {
                this.preco = response.data
            })
        },
        close() {
            this.$emit('close')
        },
        selectImg(code) {
            switch (code) {
                case 'CC':
                case 'EN':
                    return require('@images/previsao/ensolarado.png')
                case 'PC':
                case 'PI':
                    return require('@images/previsao/chuvoso.png')
                case 'NB':
                case 'NE':
                    return require('@images/previsao/nublado.png')
                case 'PN':
                    return require('@images/previsao/ensolarado-nuvem.png')
                case 'CH':
                case 'NC':
                    return require('@images/previsao/chuva.png')
                default:
                    break
                }
                return 
        }
    },
    created() {
        this.getCampo()
        this.getUnidadeClima()
        this.getUnidadePreco()
    },
    watch: {
        tempoId: function () {
            this.getClima()
        },
        precoId: function () {
            this.getPreco()
        },
    },
}
</script>

<style lang="stylus" scoped src="./AsideAcessoRapido.styl"></style>
