const textDictionary = {
	brazil: 'MapaBrasil',
	columns: 'Colunas',
	dropdowns: 'Dropdown',
	iframe: 'Iframe',
	founders: 'Fundadores',
	link: 'Botao',
	botaoarquivo: 'BotaoArquivo',
	list: 'Listagem',
	paragraph: 'Paragrafo',
	tabs: 'Abas',
	table: 'Tabela',
	timeline: 'LinhaDoTempo',
	gallery: 'Galeria',
	videogallery: 'GaleriaVideo',
	title: 'Heading',
	aplicativos: 'Aplicativos',
	box: 'Box',
	boxh: 'BoxH',
	boxr: 'BoxR',
	boxvideos: 'BoxVideos',
	videos: 'UltimosVideos',
	listas: 'ListaReproducao',
	certificates: 'Certificacoes',
	revistas: 'Revistas',
	noticias: 'Noticias',
	courses: 'Cursos',
	image: 'Figure',
	texto: 'Editor',
	premiosedestaques: 'PremiosEDestaques',
	imagegrid: 'ImageGrid',
	demonstracoes: 'Demonstracoes',
	precododia: 'PrecoDoDia',
	precorevista: 'PrecoRevista',
	listaradios: 'ListaRadios',
	mosaicobeneficios: 'MosaicoBeneficios',
	mosaicocooperados: 'MosaicoCooperados',
	beneficiosgaleria: 'BeneficiosGaleria',
	informativocoamo: 'InformativoCoamo',
	informativoacessos: 'InformativoAcessos',
	industrializacao: 'Industrializacao',
	coamoemnumeros: 'CoamoEmNumeros',
	industrialinhadotempo: 'LinhaIndustrias',
	industriamosaico: 'IndustriaMosaico',
	imagemodal : 'ImageModal',
	imagelink : 'ImageLink',
	acessorestritoitem : 'AcessoRestritoItem',
	line: `LineComponent`,
	ribbonsection: 'RibbonSection',
	socialsribbonsection: 'SocialsRibbonSection',
	homebannersection: `HomeBannerSection`,
	quickaccesssection: `QuickAccessSection`,
	mosaicohomesection: `MosaicoHomeSection`,
	newsgallerysection: `NewsGallerySection`,
	careersection: `CareerSection`,
	careercasessection: `CareerCasesSection`,
	communicationssection: `CommunicationsSection`,
	careermanagementsection: `CareerManagementSection`
}

const formDictionary = {
	select: 'Select',
	checkbox: 'CheckboxRadio',
	radio: 'CheckboxRadio',
	text: 'Input',
	number: 'Input',
	tel: 'Input',
	email: 'Input',
	textarea: 'Textarea',
	checkboxRadio: 'CheckboxRadio',
	editor: 'Content',
	file: 'Input',
	image: 'Input'
}

const faleConoscoDictionary = {
	contactbox: `ContactBox`,
	contactform: `Form`,
	alimentosform: `AlimentosForm`,
	editor: `Editor`,
	parent: `Parent`,
}

const tableDictionary = {
	thead: 'TabelaCabecalho',
	tbody: 'TabelaCorpo',
	tr: 'TabelaLinha',
	td: 'TabelaColuna',
	th: 'TabelaColunaCabecalho',
}

const components = {
	Link: () => import( /* webpackChunkName: "Link" */ '@components/Link/Link.vue'),
}

const textComponents = {
	Abas: () => import( /* webpackChunkName: "Abas" */ '@components/Abas/Abas.vue'),
	Botao: () => import( /* webpackChunkName: "Botao" */ '@components/Texto/Botao/Botao.vue'),
	BotaoArquivo: () => import( /* webpackChunkName: "BotaoArquivo" */ '@components/Texto/BotaoArquivo/BotaoArquivo.vue'),
	Colunas: () => import( /* webpackChunkName: "Colunas" */ '@sections/Pagina/Colunas/Colunas.vue'),
	Dropdown: () => import( /* webpackChunkName: "Dropdown" */ '@components/DropDown/DropDown.vue'),
	Figure: () => import( /* webpackChunkName: "Figure" */ '@components/Texto/Figure/Figure.vue'),
	Iframe: () => import( /* webpackChunkName: "Iframe" */ '@components/Iframe/Iframe.vue'),
	Fundadores: () => import( /* webpackChunkName: "Fundadores" */ '@sections/Pagina/Fundadores/Fundadores.vue'),
	Listagem: () => import( /* webpackChunkName: "Listagem" */ '@components/Texto/Listagem/Listagem.vue'),
	MapaBrasil: () => import( /* webpackChunkName: "MapaBrasil" */ '@components/MapaBrasil/MapaBrasil.vue'),
	Heading: () => import( /* webpackChunkName: "TextoTitulo" */ '@components/Texto/Titulo/Titulo.vue'),
	LinhaDoTempo: () => import( /* webpackChunkName: "LinhaDoTempo" */ '@sections/Pagina/LinhaDoTempo/LinhaDoTempo.vue'),
	ImageModal: () => import( /* webpackChunkName: "ImageModal" */ '@sections/Pagina/ImageModal/ImageModal.vue'),
	ImageLink: () => import( /* webpackChunkName: "ImageLink" */ '@sections/Pagina/ImageLink/ImageLink.vue'),
	Galeria: () => import( /* webpackChunkName: "Galeria" */ '@components/Galeria/Galeria.vue'),
	GaleriaVideo: () => import( /* webpackChunkName: "GaleriaVideo" */ '@components/GaleriaVideo/GaleriaVideo.vue'),
	Paragrafo: () => import( /* webpackChunkName: "TextoParagrafo" */ '@components/Texto/Paragrafo/Paragrafo.vue'),
	Tabela: () => import( /* webpackChunkName: "Tabela" */ '@components/Tabela/Tabela.vue'),
	Aplicativos: () => import( /* webpackChunkName: "Aplicativos" */ '@components/Aplicativos/Aplicativos.vue'),
	Box: () => import( /* webpackChunkName: "Box" */ '@components/Box/Box.vue'),
	BoxH: () => import( /* webpackChunkName: "BoxH" */ '@components/BoxH/BoxH.vue'),
	BoxR: () => import( /* webpackChunkName: "BoxR" */ '@components/BoxR/BoxR.vue'),
	BoxVideos: () => import( /* webpackChunkName: "BoxVideos" */ '@components/BoxVideos/BoxVideos.vue'),
	UltimosVideos: () => import( /* webpackChunkName: "UltimosVideos" */ '@components/UltimosVideos/UltimosVideos.vue'),
	ListaReproducao: () => import( /* webpackChunkName: "ListaReproducao" */ '@components/ListaReproducao/ListaReproducao.vue'),
	Certificacoes: () => import( /* webpackChunkName: "ListaReproducao" */ '@components/Certificacoes/Certificacoes.vue'),
	Revistas: () => import( /* webpackChunkName: "Revistas" */ '@components/Revistas/Revistas.vue'),
	Cursos: () => import( /* webpackChunkName: "Cursos" */ '@components/Cursos/Cursos.vue'),
	Editor: () => import( /* webpackChunkName: "Editor" */ '@sections/Pagina/Editor/Editor.vue'),
	PremiosEDestaques: () => import( /* webpackChunkName: "PremiosEDestaques" */ '@components/PremiosEDestaques/PremiosEDestaques.vue'),
	ImageGrid: () => import( /* webpackChunkName: "ImageGrid" */ '@components/ImageGrid/ImageGrid.vue'),
	Demonstracoes: () => import( /* webpackChunkName: "Demonstracoes" */ '@components/Demonstracoes/Demonstracoes.vue'),
	PrecoDoDia: () => import( /* webpackChunkName: "PrecoDoDia" */ '@components/PrecoDoDia/PrecoDoDia.vue'),
	PrecoRevista: () => import( /* webpackChunkName: "PrecoRevista" */ '@components/PrecoRevista/PrecoRevista.vue'),
	ListaRadios: () => import( /* webpackChunkName: "ListaRadios" */ '@components/ListaRadios/ListaRadios.vue'),
	MosaicoBeneficios: () => import( /* webpackChunkName: "MosaicoBeneficios" */ '@components/MosaicoBeneficios/MosaicoBeneficios.vue'),
	MosaicoCooperados: () => import( /* webpackChunkName: "MosaicoCooperados" */ '@components/MosaicoCooperados/MosaicoCooperados.vue'),
	BeneficiosGaleria: () => import( /* webpackChunkName: "BeneficiosGaleria" */ '@components/BeneficiosGaleria/BeneficiosGaleria.vue'),
	InformativoCoamo: () => import( /* webpackChunkName: "InformativoCoamo" */ '@components/InformativoCoamo/InformativoCoamo.vue'),
	InformativoAcessos: () => import( /* webpackChunkName: "InformativoAcessos" */ '@components/InformativoAcessos/InformativoAcessos.vue'),
	Noticias: () => import( /* webpackChunkName: "Noticias" */ '@components/Noticias/Noticias.vue'),
	Industrializacao: () => import( /* webpackChunkName: "Industrializacao" */ '@components/Industrializacao/Industrializacao.vue'),
	CoamoEmNumeros: () => import( /* webpackChunkName: "CoamoEmNumeros" */ '@components/CoamoEmNumeros/CoamoEmNumeros.vue'),
	LinhaIndustrias: () => import( /* webpackChunkName: "LinhaIndustrias" */ '@components/LinhaIndustrias/LinhaIndustrias.vue'),
	IndustriaMosaico: () => import( /* webpackChunkName: "IndustriaMosaico" */ '@components/IndustriaMosaico/IndustriaMosaico.vue'),
	AcessoRestritoItem: () => import( /* webpackChunkName: "AcessoRestritoItem" */ '@components/AcessoRestritoItem/AcessoRestritoItem.vue'),
	LineComponent: () => import( /* webpackChunkName: "Line" */ '@components/Texto/Line/Line.vue'),
	RibbonSection: () => import( /* webpackChunkName: "RibbonSection" */ '@sections/Home/RibbonSection/RibbonSection.vue'),
	SocialsRibbonSection: () => import( /* webpackChunkName: "SocialsRibbonSection" */ '@sections/Home/SocialsRibbonSection/SocialsRibbonSection.vue'),
	NewsGallerySection: () => import( /* webpackChunkName: "NewsGallerySection" */ '@sections/Home/NewsGallerySection/NewsGallerySection.vue'),
	HomeBannerSection: () => import( /* webpackChunkName: "HomeBannerSection" */ '@sections/Home/HomeBannerSection/HomeBannerSection.vue'),
	QuickAccessSection: () => import( /* webpackChunkName: "QuickAccessSection" */ '@sections/Home/QuickAccessSection/QuickAccessSection.vue'),
	MosaicoHomeSection: () => import( /* webpackChunkName: "MosaicoHomeSection" */ '@sections/Home/MosaicoHomeSection/MosaicoHomeSection.vue'),
	CareerSection: () => import( /* webpackChunkName: "CareerSection" */ '@sections/Trabalhe/CareerSection/CareerSection.vue'),
	CareerManagementSection: () => import( /* webpackChunkName: "CareerManagementSection" */ '@sections/Trabalhe/CareerManagementSection/CareerManagementSection.vue'),
	CareerCasesSection: () => import( /* webpackChunkName: "CareerCasesSection" */ '@sections/Trabalhe/CareerCasesSection/CareerCasesSection.vue'),
	CommunicationsSection: () => import( /* webpackChunkName: "CommunicationsSection" */ '@sections/Home/CommunicationsSection/CommunicationsSection.vue'),
}

const faleConoscoComponents = {
	Blocks: () => import( /* webpackChunkName: "Blocks" */ '@sections/FaleConosco/Blocks/Blocks.vue'),
	Content: () => import( /* webpackChunkName: "Content" */ '@sections/FaleConosco/Content/Content.vue'),
	ContactBox: () => import( /* webpackChunkName: "ContactBox" */ '@sections/FaleConosco/ContactBox/ContactBox.vue'),
	Editor: () => import( /* webpackChunkName: "Editor" */ '@sections/FaleConosco/Editor/Editor.vue'),
	Form: () => import( /* webpackChunkName: "Form" */ '@sections/FaleConosco/Form/Form.vue'),
	AlimentosForm: () => import( /* webpackChunkName: "AlimentosForm" */ '@sections/FaleConosco/AlimentosForm/AlimentosForm.vue'),
	Parent: () => import( /* webpackChunkName: "Parent" */ '@sections/FaleConosco/Parent/Parent.vue'),
}

const formComponents = {
	Input: () => import( /* webpackChunkName: "Input" */ '@components/Form/Input/Input'),
	Select: () => import( /* webpackChunkName: "Select" */ '@components/Form/Select/Select'),
	Textarea: () => import( /* webpackChunkName: "Textarea" */ '@components/Form/Textarea/Textarea'),
	Content: () => import( /* webpackChunkName: "Content" */ '@sections/FaleConosco/Content/Content.vue'),
	CheckboxRadio: () => import( /* webpackChunkName: "CheckboxRadio" */ '@components/Form/CheckboxRadio/CheckboxRadio')
}

const tableComponents = {
	TabelaCabecalho: () => import( /* webpackChunkName: "TabelaCabecalho" */ '@components/TabelaCabecalho/TabelaCabecalho.vue'),
	TabelaCorpo: () => import( /* webpackChunkName: "TabelaCorpo" */ '@components/TabelaCorpo/TabelaCorpo.vue'),
	TabelaLinha: () => import( /* webpackChunkName: "TabelaLinha" */ '@components/TabelaLinha/TabelaLinha.vue'),
	TabelaColuna: () => import( /* webpackChunkName: "TabelaColuna" */ '@components/TabelaColuna/TabelaColuna.vue'),
	TabelaColunaCabecalho: () => import( /* webpackChunkName: "TabelaColunaCabecalho" */ '@components/TabelaColunaCabecalho/TabelaColunaCabecalho.vue'),
}

const props = {
	contents: {
		type: [String, Object, Array],
		required: true,
	}
}

export {
	components,
	props,
	faleConoscoComponents,
	faleConoscoDictionary,
	formComponents,
	formDictionary,
	tableComponents,
	tableDictionary,
	textComponents,
	textDictionary,
}
