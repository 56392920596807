<template lang="pug">
	header#header(:class="{'ativo': menuAtivo}")
		Link(:contents="link").coamo
			h1
				|Coamo
				SvgIcon(data="@svgs/coamo-logo.svg", original)
		nav
			ul
				li(v-for="item, index in $store.getters['menus/header'].items", @click="closeMenu()")
					Link(:contents="item.route") 
						span {{ item.name }}
		div.buttons
			.left
				.search(@click="handleOpenSearch" :class="{open: $store.state.search.display }")
					input(v-model="search" ref="search", @keydown.enter="closeSearch" @blur="closeSearch")
					FontAwesomeIcon(:icon="icons.faMagnifyingGlass")
				.access
					a(href="/pt-br/servicos/acesso-restrito")
						SvgIcon(data="@svgs/acesso-restrito.svg", original)
						span ACESSO RESTRITO
			.middle
				Link(:contents="link").coamoMobile
					h1
						|Coamo
						SvgIcon(data="@svgs/coamo-logo.svg", original)
			.right
				button(@click="toggleMenu").menu
					i.first
					i.second
</template>

<script>
import Link from "@components/Link/Link";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

export default {
	name: 'component-header',
	data() {
		return {
			icons: {
				faMagnifyingGlass
			},
			menuAtivo: false,
			search: '',
			link: {
				external: false,
				link: { name: "Home" },
			},
		}
	},
	components: {
		Link,
	},
	mounted() {
		this.$store.dispatch('menus/getHeader')
	},
	methods: {
		handleOpenSearch(){
			this.search = ''
			this.$store.commit('search/setDisplay', true)
			this.$refs.search.focus()
		},
		closeSearch(){
			this.$store.commit('search/setDisplay', false)
			if (this.search) {
				if (this.$route.name != 'Busca')
					this.$router.push({ name: 'Busca', query: { q: this.search } })
				else if (this.$route.query.q != this.search)
					this.$router.replace({ name: 'Busca', query: { q: this.search } })
			}
		},
		toggleMenu(){
			this.menuAtivo = !this.menuAtivo
		},
		closeMenu(){
			this.menuAtivo = false
		},
	}
}
</script>

<style lang="stylus" scoped src="./Header.styl"></style>
