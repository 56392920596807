<template lang="pug">
    transition(name="component-aside")
        aside.main-aside(v-if="ativo", ref="componentAside", @click.self="beforeClose", @keydown.esc="beforeClose", tabindex="0" @before-close="beforeClose")
            .main-content
                button(type="button", title="Fechar", @click="beforeClose").fechar
                    FontAwesomeIcon(:icon="icons.faXmark")
                slot(:beforeClose="beforeClose")
</template>

<script>
import { faXmark } from "@fortawesome/free-solid-svg-icons";

export default {
	name: "component-aside",
	props: {
		ativo: {
			default: false,
			type: Boolean,
		},
	},
	data() {
		return {
			icons: {
				faXmark,
			},
		};
	},
	beforeDestroy() {
		document.getElementById("page").classList.remove("overflow-hidden");
	},
	watch: {
		"$props.ativo": function () {
			if (this.$props.ativo) {
				document
					.getElementById("page")
					.classList.add("overflow-hidden");
				setTimeout(() => this.$refs.componentAside.focus(), 400);
			} else {
				document
					.getElementById("page")
					.classList.remove("overflow-hidden");
			}
		},
	},
	methods: {
		beforeClose() {
			this.$store.commit("toggleShareAside", false);
			this.$store.commit("toggleEmptyUnits", false);
			this.$store.commit("toggleRadioAside", false);
			this.$store.commit("toggleRadioDownloadAside", false);
			this.$store.commit("setLinhaDoTempo", {
				ativo: false,
				content: {},
			});
			this.$store.commit("setImage", { ativo: false, content: {} });
		},
	},
};
</script>

<style lang="stylus" scoped src="./Aside.styl"></style>
<style lang="stylus">
#page
    &.overflow-hidden
        overflow hidden !important
</style>
