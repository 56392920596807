import DICTIONARY_ESTADOS from "@/mixins/unitUfs";
import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import menus from "./menus";
import search from "./search";

Vue.use(Vuex);

export default new Vuex.Store({
	state: () => ({
		requestedComponents: [],
		linhaDoTempo: {
			ativo: false,
			content: {},
		},
		imageModal: {
			ativo: false,
			content: {},
		},
		toggleShare: false,
		toggleRadio: false,
		toggleEmptyUnits: false,
		toggleRadioDownload: false,
		tabs: {},
		locale: `pt-br`,
		modal: null,
		units: [],
		radios: [],
		news: [],
		fullNews: [],
		programs: [],
		settings: [],
		searchQuery: "",
		magazines: [],
	}),
	getters: {
		tabs: (state) => (identifier) => {
			return state.tabs[identifier];
		},
		filterUnits:
			(state) =>
			(identifier = null) => {
				if (identifier)
					return state.units.filter((unit) => unit.uf == identifier);
				return state.units;
			},
		tabUnits: (state) => {
			return state.units.reduce((prev, curr, index) => {
				const tabIndex = prev.findIndex((item) => item.id == curr.uf);
				if (tabIndex >= 0) prev[tabIndex].content.push(curr);
				else
					prev.push({
						title: DICTIONARY_ESTADOS[curr.uf],
						id: curr.uf,
						content: [curr],
					});
				return prev;
			}, []);
		},
		filterRadios:
			(state) =>
			(identifier = null) => {
				if (identifier)
					return state.radios.filter(
						(radio) => radio.state_uf == identifier
					);
				return state.radios;
			},
		tabRadios: (state) => {
			return state.radios.reduce((prev, curr, index) => {
				const tabIndex = prev.findIndex(
					(item) => item.id == curr.state_uf
				);
				if (tabIndex >= 0) prev[tabIndex].content.push(curr);
				else
					prev.push({
						title: DICTIONARY_ESTADOS[curr.state_uf],
						id: curr.state_uf,
						content: [curr],
					});
				return prev;
			}, []);
		},
		magazines: (state) => (type, url) => {
			return state.magazines
				.map((revista) => {
					const date = revista.publishDate.split("-");
					const newRevista = {
						id: revista.id,
						type: revista.type,
						edition: revista.edition,
						animatedVersion: revista.animatedVersion,
						defaultVersion: revista.defaultVersion,
						image: `${url}/medias/media/${revista.image.id}`,
						ano: date[0],
						mes: [
							"Janeiro",
							"Fevereiro",
							"Março",
							"Abril",
							"Maio",
							"Junho",
							"Julho",
							"Agosto",
							"Setembro",
							"Outubro",
							"Novembro",
							"Dezembro",
						][+date[1] - 1],
					};
					return newRevista;
				})
				.filter((revista) => revista.type == type);
		},
	},
	mutations: {
		setRequestedComponents(state, payload) {
			if (payload != false) state.requestedComponents.push(payload);
			else state.requestedComponents = [];
		},
		setLinhaDoTempo(state, payload) {
			state.linhaDoTempo.ativo = payload.ativo;
			state.linhaDoTempo.content = payload.content;
		},
		setImage(state, payload) {
			state.imageModal.ativo = payload.ativo;
			state.imageModal.content = payload.content;
		},
		setTabs(state, payload) {
			if (payload == false) Vue.set(state, tabs, {});
			else Vue.set(state.tabs, payload.name, payload.value);
		},
		setLocale(state, payload) {
			state.locale = payload;
		},
		setModal(state, payload) {
			state.modal = payload;
		},
		toggleShareAside(state, payload) {
			state.toggleShare = payload;
		},
		toggleRadioAside(state, payload) {
			state.toggleRadio = payload;
		},
		toggleRadioDownloadAside(state, payload) {
			state.toggleRadioDownload = payload;
		},
		setUnits(state, payload) {
			state.units = payload.data.units;
		},
		toggleEmptyUnits(state, payload) {
			state.toggleEmptyUnits = payload;
		},
		setRadios(state, payload) {
			state.radios = payload.data.radios;
		},
		setNews(state, payload) {
			state.news = payload.data.news;
		},
		setFullNews(state, payload) {
			state.fullNews = payload.data.news;
		},
		setSettings(state, payload) {
			state.settings = payload.data.settings;
		},
		setPrograms(state, payload) {
			state.programs = payload.data.programs;
		},
		setMagazines(state, payload) {
			state.magazines = payload.data.magazines;
		},
	},
	actions: {
		setRequestedComponents({ state, commit }, payload) {
			if (
				typeof payload === "string" &&
				!state.requestedComponents.includes(item)
			)
				commit("setRequestedComponents", item);
			else if (payload.length)
				payload.forEach((item) => {
					if (!state.requestedComponents.includes(item))
						commit("setRequestedComponents", item);
				});
			else commit("setRequestedComponents", false);
		},
		async fetchUnits({ commit }) {
			try {
				const unidadesData = await axios.get(
					`${process.env.VUE_APP_API_ROUTE}/units`
				);
				commit("setUnits", unidadesData);
			} catch (e) {
				console.error(e);
			}
		},
		async fetchRadios({ commit }) {
			try {
				const radiosData = await axios.get(
					`${process.env.VUE_APP_API_ROUTE}/radios`
				);
				commit("setRadios", radiosData);
			} catch (e) {
				console.error(e);
			}
		},
		async fetchPrograms({ commit }, payload) {
			try {
				const programsData = await axios.get(
					`${process.env.VUE_APP_API_ROUTE}/programs`,
					{
						headers: {
							Authorization: `Bearer ${payload}`,
						},
					}
				);
				commit("setPrograms", programsData);
			} catch (e) {
				console.error(e);
			}
		},
		async fetchFullNews({ commit }) {
			try {
				const newsData = await axios.get(
					`${process.env.VUE_APP_API_ROUTE}/news`
				);
				commit("setFullNews", newsData);
			} catch (e) {
				console.error(e);
			}
		},
		async fetchNews({ commit }, { skip, take }) {
			try {
				const newsData = await axios.get(
					`${process.env.VUE_APP_API_ROUTE}/news/pagination?skip=${skip}&take=${take}`
				);
				commit("setNews", newsData);
			} catch (e) {
				console.error(e);
			}
		},
		async fetchSettings({ commit }) {
			try {
				const settingsData = await axios.get(
					`${process.env.VUE_APP_API_ROUTE}/settings`
				);
				commit("setSettings", settingsData);
			} catch (e) {
				console.error(e);
			}
		},
		async fetchMagazines({ commit }) {
			try {
				const magazinesData = await axios.get(
					`${process.env.VUE_APP_API_ROUTE}/magazines`
				);
				commit("setMagazines", magazinesData);
			} catch (e) {
				console.error(e);
			}
		},
	},
	modules: {
		menus,
		search,
	},
});
