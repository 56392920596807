/**
 * Sitemap
 * 	Referência: https://github.com/cheap-glitch/vue-cli-plugin-sitemap#readme
 * 	Adicionar o seguinte na rota em que precise alterar manualmente os valores do sitemap, com os respectivos valores:
		meta: {
			sitemap: {
				lastmod: new Date(),
				priority: 0.8,
				changefreq: 'daily',
			}
		}
 */

let routes = [
	{
		path: '/',
		name: 'Home',
		component: () => import(/* webpackChunkName: "Pagina" */ '@views/Pagina/Pagina'),
	},
	{
		path: '/onde-encontrar',
		name: 'OndeEncontrar',
		component: () => import(/* webpackChunkName: "OndeEncontrar" */ '@views/OndeEncontrar/OndeEncontrar'),
	},
	{
		path: '/contato',
		name: 'Contato',
		component: () => import(/* webpackChunkName: "Contato" */ '@views/Contato/Contato'),
	},
	{
		path: '/trabalhe-conosco',
		name: 'Trabalhe',
		component: () => import(/* webpackChunkName: "Pagina" */ '@views/Pagina/Pagina'),
	},
	{
		path: '/industrias',
		name: 'Industrias',
		component: () => import(/* webpackChunkName: "Pagina" */ '@views/Pagina/Pagina'),
	},
	{
		path: '/preco-do-dia',
		name: 'Preço',
		component: () => import(/* webpackChunkName: "Preco" */ '@views/Preco/Preco'),
	},
	// {
	// 	path: '/acesso-restrito',
	// 	name: 'AcessoRestrito',
	// 	component: () => import(/* webpackChunkName: "Pagina" */ '@views/Pagina/Pagina'),
	// 	meta: {
	// 		sitemap: { ignoreRoute: true },
	// 	}
	// },
	// {
	// 	path: '/trabalhe-conosco',
	// 	name: 'TrabalheConosco',
	// 	component: () => import(/* webpackChunkName: "Pagina" */ '@views/Pagina/Pagina'),
	// 	meta: {
	// 		sitemap: { ignoreRoute: true },
	// 	}
	// },
	// {
	// 	path: '/produtos/*',
	// 	name: 'Produtos',
	// 	component: () => import(/* webpackChunkName: "Pagina" */ '@views/Pagina/Pagina'),
	// 	meta: {
	// 		sitemap: { ignoreRoute: true },
	// 	}
	// },
	// {
	// 	path: '/institucional/*',
	// 	name: 'Institucional',
	// 	component: () => import(/* webpackChunkName: "Pagina" */ '@views/Pagina/Pagina'),
	// 	meta: {
	// 		sitemap: { ignoreRoute: true },
	// 	}
	// },
	// {
	// 	path: '/servicos/*',
	// 	name: 'Servicos',
	// 	component: () => import(/* webpackChunkName: "Pagina" */ '@views/Pagina/Pagina'),
	// 	meta: {
	// 		sitemap: { ignoreRoute: true },
	// 	}
	// },
	// {
	// 	path: '/comunicacao/*',
	// 	name: 'Comunicacao',
	// 	component: () => import(/* webpackChunkName: "Pagina" */ '@views/Pagina/Pagina'),
	// 	meta: {
	// 		sitemap: { ignoreRoute: true },
	// 	}
	// },
	// {
	// 	path: '/noticias',
	// 	name: 'Noticias',
	// 	component: () => import(/* webpackChunkName: "Listagem" */ '@views/Listagem/Listagem'),
	// 	meta: {
	// 		sitemap: { ignoreRoute: true }
	// 	}
	// },
	{
		path: '/:locale/fale-conosco',
		name: 'FaleConosco',
		component: () => import(/* webpackChunkName: "FaleConosco" */ '@views/FaleConosco/FaleConosco'),
		meta: {
			sitemap: { ignoreRoute: true }
		}
	},
	// {
	// 	path: '/informativo-coamo',
	// 	name: 'Informativo',
	// 	component: () => import(/* webpackChunkName: "Informativo" */ '@views/Informativo/Informativo'),
	// 	meta: {
	// 		sitemap: { ignoreRoute: true }
	// 	}
	// },
	// {
	// 	path: '/unidades',
	// 	name: 'Unidades',
	// 	component: () => import(/* webpackChunkName: "Unidades" */ '@views/Unidades/Unidades'),
	// 	meta: {
	// 		sitemap: { ignoreRoute: true }
	// 	}
	// },
	// {
	// 	path: '/noticias',
	// 	name: 'Noticias',
	// 	component: () => import(/* webpackChunkName: "Noticias" */ '@views/Noticias/Noticias'),
	// 	meta: {
	// 		sitemap: { ignoreRoute: true }
	// 	}
	// },
	// {
	// 	path: '/noticias/:slug/:locale?',
	// 	name: 'Noticia',
	// 	component: () => import(/* webpackChunkName: "Pagina" */ '@views/Pagina/Pagina'),
	// 	meta: {
	// 		sitemap: { ignoreRoute: true }
	// 	}
	// },
	{
		path: '/resultados-da-busca',
		name: 'Busca',
		component: () => import(/* webpackChunkName: "Busca" */ '@views/Busca/Busca'),
	},
	{
		path: '/:locale/noticias/:slug',
		name: 'Noticia',
		component: () => import(/* webpackChunkName: "Pagina" */ '@views/Pagina/Pagina'),
		meta: {
			sitemap: { ignoreRoute: true }
		}
	},
	{
		path: '/:locale/:category/:subcategory?/:slug?',
		name: 'Pagina',
		component: () => import(/* webpackChunkName: "Pagina" */ '@views/Pagina/Pagina'),
		meta: {
			sitemap: { ignoreRoute: true }
		}
	},
]

export default routes
