<template lang="pug">
	footer#footer
		button(@click="scrollToTop").goTop
			FontAwesomeIcon(:icon="icons.faChevronUp")
		.wrapper
			.left
				Link(:contents="home").coamo
					h1
						|Coamo
						SvgIcon(data="@svgs/coamo-logo.svg", original)
				.informacoes
					//- p 
						| Razão Social
						strong Coamo Agroindustrial Cooperativa
					p 
						| Administração Central
						br
						| Rua Fioravante João Ferri, 99
						br
						| Jardim Alvorada - Caixa Postal 460.
						br
						| Campo Mourão - PR
						br
						| CEP: 87308-445
					p Telefone <br>44 3599-8000
			.right
				ul(v-for="group, index in $store.getters['menus/footer'].items")
					li.title {{ group.name }}
					li(v-for="item in group.items")
						Link(:contents="item.route") {{ item.name }}
		.copyright
			span
				strong © {{ year }} Coamo Agroindustrial Cooperativa
				|- Todos os direitos reservados.
</template>

<script>
import Link from '@components/Link/Link'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'

export default {
	name: 'component-footer',
	components: {
		Link
	},
	data() {
		return {
			icons: {
				faChevronUp,
			},
			home: {
				title: `Página Inicial`,
				external: false,
				link: { name: `Home` },
			},
		}
	},
	computed: {
		year() {
			return new Date().getFullYear()
		}
	},
	mounted(){
		this.$store.dispatch('menus/getFooter')
	},
	methods: {
		scrollToTop() {
			document.querySelector('#page').scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			})
		},
	},
}
</script>

<style lang="stylus" scoped src="./Footer.styl"></style>
