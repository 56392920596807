<template lang="pug">
    .link
        router-link(:to="link", v-if="!external && link")
            slot
        a(:href="link", v-else-if="link && newTab", target="_blank", rel="noopener noreferrer")
            slot
        a(:href="link", v-else-if="link && !newTab", rel="noopener noreferrer")
            slot
        slot(v-else)
</template>

<script>
import { props } from '@/mixins/component'

export default {
    name: "component-link",
    props,
    computed: {
        external() {
            if(this.contents.external)
                return this.contents.external
            return false
        },
        link() {
            return this.contents.link
        },
        newTab() {
            return this.contents.newTab
        }
    },
}
</script>

<style lang="stylus" scoped src="./Link.styl"></style>
