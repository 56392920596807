import Axios from 'axios'

const $axios = Axios.create({
	baseURL: `${process.env.VUE_APP_API_ROUTE}/`,
})

export default {
	namespaced: true,
	state: () => ({
		results: [],
		display: false,
		loading: true,
	}),
	getters: {
		results(state) {
			return {
				pages: state.results.filter(item => item.type == 'page'),
				news: state.results.filter(item => item.type == 'news'),
			}
		}
	},
	mutations: {
		setResults(state, payload) {
			state.loading = false;
			state.results = [ ...payload ]
		},
		setDisplay(state, payload) {
			state.display = payload
		},
	},
	actions: {
		async getResults({ commit, rootState }, search) {
			const response = await $axios.get(`search/${rootState.locale}?q=${search}`)
			if (response.status == 200) {
				commit('setResults', response.data.results)
			}
		}
	}
}
