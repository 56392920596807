<template lang="pug">
    div(v-if="cookies")#cookies
        p.text Coletamos dados para melhorar o desempenho e segurança do nosso website. Você pode consultar nossa 
            a(href="https://www.coamo.com.br/pt-br/institucional/politica-de-privacidade-e-cookies?tabQuery=3") Política de Cookies 
            |e 
            a(href="https://www.coamo.com.br/pt-br/institucional/politica-de-privacidade-e-cookies?tabQuery=1") Aviso Externo de Privacidade 
            |em nosso site.
        button(type="button" @click="acceptCookies").accept ENTENDI
</template>

<script>
export default {
    name: 'component-cookies',
    data() {
        return {
            cookies: true,
        }
    },
    methods: {
        acceptCookies() {
            localStorage.setItem('Cookies', 'accepted')
            this.cookies = false;
        },
    },
    mounted() {
        if (localStorage.getItem('Cookies') === 'accepted') this.cookies = false;
    },
}
</script>

<style lang="stylus" scoped src="./Cookies.styl"></style>