import '@stylus/main.styl'

import { App } from '@templates'
import Axios from 'axios'
import router from '@/router'
import store from '@/store'
import Vue from 'vue'
// https://fontawesome.com/docs/web/use-with/vue/dig-deeper
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { VueSvgIcon } from '@yzfe/vue-svgicon'
import '@yzfe/svgicon/lib/svgicon.css'
import VueMeta from 'vue-meta'

Vue.config.productionTip = false

Vue.component('SvgIcon', VueSvgIcon)
Vue.component('FontAwesomeIcon', FontAwesomeIcon)
Vue.use(VueMeta)

Vue.prototype.$axios = Axios.create({
	baseURL: `${process.env.VUE_APP_API_ROUTE}/`
})

Vue.component('SvgIcon', VueSvgIcon)

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
